/* Upload button */
.upload-button {
  position: fixed;
  bottom: 8px; /* Jarak dari bawah */
  right: 8px; /* Jarak dari kanan */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Lebar tombol */
  height: 50px; /* Tinggi tombol */
  background-color: transparent; /* Latar belakang transparan */
  border: none; /* Menghilangkan border */
  padding: 0; /* Menghilangkan padding */
  z-index: 1000; /* Pastikan tombol di atas elemen lain */
}

.upload-button img {
  width: 90%; /* Menyesuaikan lebar gambar dengan tombol */
  height: auto; /* Menjaga proporsi gambar */
}

.upload-button:hover img {
  opacity: 0.8; /* Mengubah opacity saat hover */
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  .upload-button {
      width: 45px; /* Menyusutkan ukuran tombol pada tablet */
      height: 45px;
      bottom: 10px; /* Menambah sedikit jarak di layar yang lebih kecil */
      right: 10px;
  }

  .upload-button img {
      width: 85%; /* Sesuaikan ukuran gambar di tablet */
  }
}

@media (max-width: 480px) {
  .upload-button {
      width: 40px; /* Lebar tombol lebih kecil di layar mobile */
      height: 40px;
      bottom: 12px; /* Tambahkan jarak lebih besar dari bawah untuk kenyamanan */
      right: 12px;
  }

  .upload-button img {
      width: 80%; /* Menyesuaikan ukuran gambar lebih kecil di mobile */
  }
}
