/* Reset dasar untuk elemen */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  font-family: 'Press Start 2P', cursive;
  width: 100%;
  background-color: var(--nav-bg-color);
  padding: 15px 20px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: background-color 0.3s ease;
  color: #0ff; /* Warna neon */
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5), 0 0 10px rgba(0, 255, 255, 0.4);
}


nav.scrolled-out {
  background-color: rgba(0, 0, 0, 0.9);
}

.logo {
  color: var(--nav-text-color);
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  margin-right: 20px;
  color: var(--nav-text-color);
  text-decoration: none;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-links a:hover {
  background-color: var(--button-bg-color);
  color: #fff;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.menu-toggle div {
  width: 100%;
  height: 3px;
  background-color: var(--nav-text-color);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.menu-toggle.open .bar1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.open .bar2 {
  opacity: 0;
}

.menu-toggle.open .bar3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Style responsif untuk layar kecil (mobile) */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: var(--nav-bg-color);
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links a {
    padding: 10px;
    margin: 10px 0;
    font-size: 18px;
  }

  .menu-toggle {
    display: flex;
  }
}

/* Header search */
header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--nav-bg-color);
  padding: 20px;
  transition: background-color 0.3s, position 0.3s ease-in-out;
  z-index: 999;
}

header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.search-box {
  width: 80%;
  max-width: 600px;
  padding: 5px 11px;
  border-radius: 25px;
  border: 2px solid var(--button-bg-color);
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  font-size: 13px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-box::placeholder {
  color: var(--primary-text-color);
  opacity: 0.7;
}

.search-box:focus {
  outline: none;
  border-color: var(--button-bg-color);
  box-shadow: 0 0 10px rgba(68, 143, 255, 0.5);
}

/* Responsif untuk layar kecil */
@media (max-width: 768px) {
  header {
    padding: 15px;
  }

  .search-box {
    width: 90%;
    font-size: 14px;
  }
}
