:root {
  --primary-bg-color: #ffffff;
  --primary-text-color: #000000;
  --secondary-bg-color: #f0f0f0;
  --button-bg-color: #007bff;
  --button-text-color: #ffffff;
  --nav-bg-color: #007bff;
  --nav-text-color: #ffffff;
  --nav-hover-bg-color: #0056b3;
  --popup-bg-color: rgba(0, 0, 0, 0.7);
  --popup-content-bg-color: #ffffff;
  --popup-text-color: #000000;
}


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  transition: background-color 0.3s, color 0.3s;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

.video-item {
  display: grid;
  place-items: center; /* Memusatkan item secara horizontal dan vertikal */
  margin-top: 0px; /* Jarak dari elemen di atas */
}

/* CSS untuk iframe */
.tiktok-iframe {
  width: 100%; /* Memastikan iframe memanfaatkan lebar kontainer */
  height: 500px; /* Menentukan tinggi iframe */
  border: none; /* Menghapus border iframe */
  border-radius: 5px 5px 0px 0px;
  margin-top: 10%; /* Jarak dari elemen di atas */
}

/* Untuk Webkit-based browsers (Chrome, Safari) */
.tiktok-iframe::-webkit-scrollbar {
  display: none; /* Menyembunyikan scrollbar di dalam iframe */
}

/* Untuk Firefox */
.tiktok-iframe {
  scrollbar-width: none; /* Sembunyikan scrollbar di Firefox */
}


.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary-bg-color);
  margin-bottom: 10px;
}

.tiktok-embed {
  /* border: 2px solid #000000; */
  background-color: var(--primary-bg-color);
  margin-top: 1;

  box-sizing: border-box;
}


.download-btn {
  display: inline-block; /* Agar tombol tidak mengisi seluruh lebar kontainer */
  padding: 10px 0;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 0px 0px 5px 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  max-width: 605px;
  min-width: 325px;
  transition: background-color 0.3s;
}

.download-btn:hover {
  background-color: var(--nav-hover-bg-color);
}
/* Popup Styles */

.video-info .LinesEllipsis {
  display: none; /* Sembunyikan elemen title di bawah tombol download */
}

.popup {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--popup-bg-color);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto; /* Tambahkan ini untuk mengatur overflow pada popup */
}

.popup-content {
  background-color: var(--popup-content-bg-color);
  padding: 20px;
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  max-width: 500px;
  text-align: center;
  position: relative;
  overflow-y: auto; /* Tambahkan ini untuk memungkinkan scroll vertikal pada konten */
  max-height: 90%; /* Batasi tinggi konten untuk menjaga agar popup tetap dalam viewport */
}

.popup-content h3 {
  margin-top: 0;
  color: var(--popup-text-color);
  font-size: 1.5rem;
}

.popup-content a {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  color: #fff; /* Warna teks */
  background-color: var(--button-bg-color); /* Warna latar belakang tombol */
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.popup-content a:hover {
  background-color: #929292; /* Warna latar belakang saat hover */
  color: #fff; /* Warna teks saat hover */
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--popup-text-color);
  transition: color 0.3s;
}

.popup-close:hover {
  color: #ff0000;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .popup-content {
    width: 95%;
    padding: 15px;
    max-height: 75%;
  }

  .popup-content h3 {
    font-size: 1.25rem;
  }

  .popup-content a {
    padding: 8px 16px;
    font-size: 0.875rem;
  }

  .popup-close {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .popup-content {
    width: 90%;
    padding: 10px;
  }

  .popup-content h3 {
    font-size: 1rem;
  }

  .popup-content a {
    padding: 6px 12px;
    font-size: 0.75rem;
  }

  .popup-close {
    font-size: 18px;
  }
}
